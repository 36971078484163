"use client";

import {
  HomeIcon,
  MagnifyingGlassCircleIcon,
  PlusCircleIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeIconSolid,
  MagnifyingGlassCircleIcon as MagnifyingGlassIconSolid,
  PlusCircleIcon as PlusIconSolid,
  VideoCameraIcon as VideoCameraIconSolid,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { usePathname } from "next/navigation";
import React from "react";

const navigationItems = [
  { name: "Home", href: "/", icon: HomeIcon, activeIcon: HomeIconSolid },
  {
    name: "Search",
    href: "/search",
    icon: MagnifyingGlassCircleIcon,
    activeIcon: MagnifyingGlassIconSolid,
  },
  { name: "Create", href: "/create", icon: PlusCircleIcon, activeIcon: PlusIconSolid },
  {
    name: "Scan",
    href: "/scan",
    icon: VideoCameraIcon,
    activeIcon: VideoCameraIconSolid,
    iconStyle: "bg-blue-500 rounded-4xl h-8 w-8 p-2 text-white -mt-1",
  },
];

export function Navbar() {
  const pathname = usePathname();
  const [activeTab, setActiveTab] = React.useState<string>(pathname || "/");

  React.useEffect(() => {
    setActiveTab(window.location.pathname || "/");
  }, [pathname]);

  return (
    <nav
      className="fixed w-screen h-[60px]
         dark:bg-gray-800  bg-white 
          bottom-0 border-t border-gray-200 overflow-hidden
      "
    >
      <div className="mx-auto max-w-md">
        <ul
          className="flex justify-start
                       sm:justify-around"
        >
          {navigationItems.map((item) => (
            <li key={item.name} className="flex-1">
              <Link
                href={item.href}
                className={`flex flex-col items-center justify-center text-center                  md:dark:text-gray-300 md:hover:text-gray-900 md:ark:hover:text-white px-4 py-4
                       sm:dark:text-gray-500 sm:hover:text-white sm:dark:hover:text-gray-300 ${
                         activeTab == item.href ? "opacity-100" : "opacity-60"
                       }`}
              >
                {activeTab == item.href ? (
                  <item.activeIcon className={"h-6 w-6 mb-1 " + (item.iconStyle ?? "")} />
                ) : (
                  <item.icon className={"h-6 w-6 mb-1 " + (item.iconStyle ?? "")} />
                )}
                <span className="hidden md:inline text-xs">{item.name}</span>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
}
